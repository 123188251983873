<template>
  <div class="a-page">
    <a-header>
      <template v-slot:start>
        <b-navbar-brand to="/zoznam/testy">
          <img :src="require('@/plugins/app/_theme/img/logo-drilleo.svg')" alt="">
        </b-navbar-brand>
      </template>
      <template v-slot:center>
        <b-nav pills class="not-exact-active">
          <b-nav-item to="/zoznam/testy">Zoznam testov</b-nav-item>
          <b-nav-item to="/zoznam/skupiny">Zoznam skupín</b-nav-item>
        </b-nav>
      </template>
      <template v-slot:end>
        <div class="d-flex flex-column align-items-center">
          <b-link class="logout" @click="logout" href="#">Odhlásiť sa</b-link>
          <div class="w-color-primary usermail"><strong>({{ usermail }})</strong></div>
        </div>
      </template>
    </a-header>

    <router-view />
  </div>
</template>

<script>
export default {

  components: {
    'a-header': () => import('@/plugins/app/_layout/a-header.vue')
  },

  computed: {
    usermail: function() {
      return this.$store.getters['wAuth/user'].email
    }
  },

  methods: {
    async logout() {
      try {
        await this.$store.dispatch('wAuth/logout')
        await this.$router.push({name: 'Home'})
      } catch(err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.logout
  line-height: 1rem
  color: var(--a-color-black)

.usermail
  font-size: .75rem
</style>
